import React from "react";

import StickyBox from "react-sticky-box";

import NavigationBar from "./components/common/NavigationBar";
import Feed from "./components/common/Feed";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Banner from "./components/common/Banner";
import CompanyBanner from "./components/channel/Banner";
import CompanyCard from "./components/channel/CompanyCard";
import PreLoad from "./components/common/PreLoad";
import Block1 from "./components/channel/blocks/1";
import Block2 from "./components/channel/blocks/2";
import Block3 from "./components/channel/blocks/3";
import Block4 from "./components/channel/blocks/4";
import Block5 from "./components/channel/blocks/5";
import Block6 from "./components/channel/blocks/6";
import Block7 from "./components/channel/blocks/7";
import Block8 from "./components/channel/blocks/8";
import Block9 from "./components/channel/blocks/9";
import Block10 from "./components/channel/blocks/10";
import Block11 from "./components/channel/blocks/11";
import Block12 from "./components/channel/blocks/12";
import Block13 from "./components/channel/blocks/13";
import Block14 from "./components/channel/blocks/14";
import Block15 from "./components/channel/blocks/15";
import Block16 from "./components/channel/blocks/16";
import Block17 from "./components/channel/blocks/17";
import Block18 from "./components/channel/blocks/18";
import ScriptCache from "../utils/ScriptCache";
import PartnerBranding from "./components/common/PartnerBranding";
import MetaTags from "../utils/MetaTags";
import NotFound from "./components/common/NotFound";
import CompanyResults from "./components/search/CompanyResults";
import OrganizationSchema from "./components/channel/OrganizationSchema";

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      companyNotFound: false
    }
  }

  componentDidMount() {
    this._loadJs()
    this._loadCompany(this.props.companySlug)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this._loadCompany(nextProps.companySlug)
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _loadCompany(companySlug){
    Backend.getChannel(companySlug, this.state.partner.slug)
      .then(channel => {
        let data = General.getTrafficData(this.props.partner)
        data["company"] = channel.company.id
        Backend.logTraffic(data)
        this.setState({
          channel
        }, () => {
          if(channel.company.branding_channel){
            this._loadBlocks(channel.slug)
          }
        })
      }).catch(() => {
      this.setState({channel: true, companyNotFound: true})
    })
  }

  _loadBlocks(companySlug){
    Backend.getBlocks(companySlug).then(blocks => {
      this.setState({blocks}, () => {
        setTimeout(() => {
        }, 5000)
      })
    })
  }

  _renderBrandedCompany(){
    let{
      blocks,
      channel,
      partner
    } = this.state

    if(!blocks) return null

    setTimeout(() => {
      General.updateAll()
    }, 1000)

    return blocks.map(contentBlock => {
      switch (contentBlock.block.id) {
        case 1:
          return <Block1 contentBlock={contentBlock}/>
        case 2:
          return <Block2 contentBlock={contentBlock}/>
        case 3:
          return <Block3 contentBlock={contentBlock} channel={channel} partner={partner}/>
        case 4:
          return <Block4 contentBlock={contentBlock}/>
        case 5:
          return <Block5 contentBlock={contentBlock}/>
        case 6:
          return <Block6 contentBlock={contentBlock}/>
        case 7:
          return <Block7 contentBlock={contentBlock}/>
        case 8:
          return <Block8 contentBlock={contentBlock}/>
        case 9:
          return <Block9 contentBlock={contentBlock}/>
        case 10:
          return <Block10 contentBlock={contentBlock}/>
        case 11:
          return <Block11 contentBlock={contentBlock}/>
        case 12:
          return <Block12 contentBlock={contentBlock}/>
        case 13:
          return <Block13 contentBlock={contentBlock}/>
        case 14:
          return <Block14 contentBlock={contentBlock}/>
        case 15:
          return <Block15 contentBlock={contentBlock}/>
        case 16:
          return <Block16 contentBlock={contentBlock}/>
        case 17:
          return <Block17 contentBlock={contentBlock}/>
        case 18:
          return <Block18 contentBlock={contentBlock}/>
        default:
          return null
      }
    })
  }

  _renderBasicCompany(){
    let{
      channel,
      partner,
    } = this.state

    let followUrl = `https://jobbio.com/talent/register?company=${channel.slug}&source=${partner.slug}`;

    return (
      <>
        <Banner
          image={channel.banner || General.getRandomBannerImage()}
          className={"cover height-50"}
        >
          <CompanyBanner
            channel={channel}
            partner={partner}
          />
        </Banner>
        <section className="maincolumns job sticky-sidebars">
          <div className="container">
            <div className="row">
              <Feed
                partner={partner.slug}
                channel={channel.slug}
                className={"col-lg-9 middlecol mb-5 pb-3"}
              />
              <CompanyCard
                channel={channel}
                partner={partner}
              />
            </div>
          </div>
        </section>
        <CTASection
          title={`Keep Up To Date With ${channel.name}`}
          subline={''}
          url={followUrl}
          buttonTitle={"FOLLOW"}
        />
      </>
    )
  }

  render() {
    let{
      channel,
      partner,
      companyNotFound
    } = this.state

    if(!channel) return <PreLoad/>
    if(companyNotFound) return (
      <NotFound type={"companies"} partner={partner}>
        <section className="maincolumns job sticky-sidebars">
          <div className="container">
            <div className="">
              <h4 className="color--error text-center">
                The job you're looking for is no longer active but you can search for similar companies.
              </h4>
              <CompanyResults
                searchTerm={""}
                partner={partner}
              />
            </div>
          </div>
        </section>
      </NotFound>
    )

    let shareTitle = `${channel.name} Jobs & Careers | ${partner.name}`;
    let description = `Find the Latest jobs at ${partner.name} today. 
                       Connect with the company and learn about their culture. You can also follow this 
                       channel to make sure you don't miss any career opportunity at ${channel.name} that will come 
                       available in the future. Find your dream job now.`;
    let url = window.location.href;
    let shareImage = partner.logo;

    let canonicalLink = `${window.General.JobbioSite}/${channel.slug}`

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage, canonicalLink) }
        <NavigationBar
          partner={partner}
          opacity={' bar--absolute bar--transparent '}
        />
        {
          channel.company.branding_channel ?
          this._renderBrandedCompany() :
          this._renderBasicCompany()
        }

        <OrganizationSchema company={channel.company} />

        <Footer
          partner={partner}
        />
      </>
    )
  }
};