import React from "react";

import StickyBox from "react-sticky-box";

import NavigationBar from "./components/common/NavigationBar";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Banner from "./components/common/Banner";
import ArticleBanner from "./components/article/Banner"
import Content from "./components/article/Content";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import ItemsSection from "./components/common/ItemsSection";

export default class Article extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  componentDidMount() {
    this._loadJs()
    this._loadArticle(this.props.articleSlug, this.state.partner.slug)
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _loadArticle(articleSlug, partner){
    Backend.getArticle(articleSlug, partner)
      .then(article => {
        let data = General.getTrafficData(this.props.partner)
        data["article"] = article.id
        Backend.logTraffic(data)
        this.setState({
          article
        }, () => {
          this._loadSimilarArticles(article, partner)
          General.updateImageBackgrounds()
        })
      }).catch(() => {
      this.setState({notFound: true})
    })
  }

  _loadSimilarArticles(article, partner){
    Backend.getSimilarArticles(article, partner).then(response => {
      this.setState({articles: response.results})
    })
  }

  render() {
    let{
      article,
      partner,
      articles
    } = this.state

    if(!article) return <PreLoad/>

    let shareTitle = `Career Advice | ${partner.name}`;
    let description = `Read career advice from our experts. They cover topics like Interview techniques, salary negotiation, working remotely and much more.`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          partner={partner}
          opacity={' bar--absolute bar--transparent '}
        />
        <Banner
          image={article.image}
          className={"cover height-50"}
        >
          <ArticleBanner
            article={article}
            partner={partner}
          />
        </Banner>
        <Content
          article={article}
        />
        <ItemsSection
          title={"Related Articles"}
          link={window.General.ArticlesPage}
          items={articles}
          type={"articles"}
        />
        <Footer
          partner={partner}
        />
      </>
    )
  }
};