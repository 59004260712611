import React from "react";
import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Image from "../../../utils/Image";
import Backend from "../../../utils/Backend";


export default class FeaturedJob extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleClick(job){
    let {
      partner
    } = this.state

    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug);

    if(job.redirect){
      job.redirect = Indeed.getIndeedTrackingLink(job.redirect, partner)
      Backend.jobRedirect(job, partner, job.redirect).then(() => {
        window.open(job.redirect, '_blank');
        window.open(jobPage, '_self');
      })
    }else{
      window.open(jobPage, '_self');
    }
  }

  _renderFeaturedJobs(){
    let {
      partner
    } = this.state

    return partner.featured_jobs.map(job => {
      let link = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug)

      let impressionUrl = !!job.impression_url

      return (
        <a
          className="row"
          href={link}
          onClick={e => {
            e.preventDefault()
            this._handleClick(job)
          }}
        >
          {/*{impressionUrl && Indeed.setIndeedImpressionImage(job.impression_url)}*/}
          <img className="logo img-rounded" src={`${Image.getImageUrl(job.company.image_logo, "other-options")}`} style={{borderRadius: 50}}/>
          <span className="col-sm-12">
            <span>{job.title}</span>
            <small>{job.company.name}</small>
          </span>
        </a>
      )
    })
  }


  render() {
    let {
      partner
    } = this.state

    if(partner.featured_jobs.length === 0) return null

    return (
      <>
        <div className="section" style={{marginBottom: 50}} id="featured-jobs">
          <h4 style={{textAlign: 'center'}}>Featured Job</h4>
          <div
            className="feature feature-1 min-height-auto boxed boxed--border popularcompanies"
            style={{backgroundColor: '#f8f8f8', border: '1px solid #ececec'}}
          >
            {this._renderFeaturedJobs()}
          </div>
        </div>
      </>
    )
  }
}