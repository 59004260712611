import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import FeaturedCompanies from "./components/homepage/FeaturedCompanies";
import FeaturedJob from "./components/homepage/FeaturedJob";
import PartnerCard from "./components/homepage/PartnerCard";
import OtherResults from "./components/common/OtherResults";
import Feed from "./components/common/Feed";
import ItemsSection from "./components/common/ItemsSection";
import InfoTabs from "./components/homepage/InfoTabs";
import InfoBoxes from "./components/homepage/InfoBoxes";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Banner from "./components/common/Banner";
import SearchBar from "./components/common/SearchBar";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  componentDidMount() {
    this._loadJs()
    Backend.getPartnerCompanies(this.props.partner, true).then(companies => {
      this.setState({companies: companies.results})
    })
    let data = General.getTrafficData(this.props.partner)
    data["homepage"] = true
    Backend.logTraffic(data)
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  render() {
    let{
      partner,
      companies
    } = this.state

    let viewMore = window.General.CompaniesPage

    let shareTitle = `${partner.name} Job Search - Find The Latest Jobs On ${partner.name}`;
    let description = `Find jobs and careers on ${partner.name} exclusive jobs network powered by Jobbio. Learn about the companies and apply directly. Your job search starts here.`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          opacity={' bar--transparent bar--absolute '}
          partner={partner}
          showMenu={true}
        />
        <Banner
          image={partner.banner}
          overlay={partner.settings.banner_overlay}
          className={`cover height-${partner.settings.banner_size || '70'}`}
        >
          <div className="col-lg-12">
            <h1 id="name">{partner.settings.banner_title}</h1>
            <p className="lead" id="caption">{partner.caption}</p>
            <SearchBar
              page={"jobs"}
              home={true}
            />
          </div>
        </Banner>
        <FeaturedCompanies
          partner={partner}
        />
        <section className="maincolumns channel-topic sticky-sidebars">
          <div className="container">
            <div className="row">
              <Feed
                partner={partner.slug}
                channel={partner.slug}
                search={true}
              />
              <OtherResults
                partner={partner}
                title={partner.settings.companies_title}
                objects={companies}
                type={"company"}
                viewMore={viewMore}
              />
              <PartnerCard
                partner={partner}
              />
            </div>
          </div>
        </section>
        {
          partner.settings.show_articles &&
          <ItemsSection
            partner={partner}
            type={"articles"}
          />
        }
        {
          partner.settings.show_sub_channels &&
          <ItemsSection
            partner={partner}
            type={"channels"}
          />
        }
        {
          partner.settings.show_info_tabs &&
          <InfoTabs
            partner={partner}
          />
        }
        {
          partner.settings.show_info_boxes &&
          <InfoBoxes
            partner={partner}
          />
        }
        <CTASection
          title={partner.settings.floating_title}
          subline={partner.settings.floating_subline}
          url={partner.settings.floating_url}
          buttonTitle={partner.settings.floating_text}
        />
        <Footer
          partner={partner}
        />
      </>
    )
  }
}