import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import Footer from "./components/common/Footer";

import Banner from "./components/search/Banner";
import General from "../utils/General";
import JobResults from "./components/search/JobResults";
import Backend from "../utils/Backend";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";
import SquareLogo from "./components/common/SquareLogo";
import Image from "../utils/Image";

export default class CompanyJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      companySlug: props.companySlug,
      searchTerm: General.getUrlParameter("searchTerm"),
      location: General.getUrlParameter("location"),
      pageCount: General.getUrlParameter("page"),
    }
  }

  componentDidMount() {
    this._loadJs()
    this._loadCompany(this.props.companySlug)
  }

  _loadJs() {
    ScriptCache.loadDefaults()
  }

  _loadCompany(companySlug) {
    Backend.getChannel(companySlug, this.state.partner.slug)
      .then(channel => {
        let data = General.getTrafficData(this.props.partner)
        data["company_jobs"] = true
        data["company"] = channel.company.id
        Backend.logTraffic(data)
        this.setState({
          channel
        })
      }).catch(() => {
        this.setState({ channel: true, companyNotFound: true })
      })
  }

  render() {
    let {
      companySlug,
      searchTerm,
      location,
      channel,
      partner,
      filters,
      pageCount
    } = this.state

    if (!channel) return <PreLoad />

    let shareTitle = `Jobs & Careers at ${channel.name} - Search Vacancies | ${partner.name}`;
    let description = `Check out the current jobs and career opportunities at ${channel.name}. Apply directly here!
                        ${channel.name} is currently hiring for a range of positions.`;
    let url = window.location.href;
    let shareImage = partner.logo;
    let companyLink = window.General.CompanyPage.replace("[company]", channel.company.slug)
    let companyJobsPage = window.General.CompanyJobsPage.replace("[company]", channel.company.slug)

    return (
      <>

        {MetaTags.render(shareTitle, description, null, url, shareImage)}

        <div className="pages company-jobs ui-jobs">

          <NavigationBar
            opacity={'bar--absolute '}
            partner={partner}
            headerFixed='true'
          />

          <div className={'main-container full-page '}>
            

              {/* Starts : Top Company details */}
              <div className="details">
                <div className="container">

                  <div className="row">
                    <div className="col-auto">
                      <div className="logo">
                        {channel.logo &&
                          <img alt={`${channel.name} logo`} src={Image.getImageUrl(channel.logo, "company-logo")} />
                        }
                        {!channel.logo &&
                          <h2>
                            {General.getInitials(channel.name)}
                          </h2>
                        }
                      </div>
                    </div>
                    <div className="col">
                      <h1>{channel.name} Jobs</h1>
                      <ul>
                        <li>
                            <i class="icon icon--sm icon-Map-Marker"></i>
                            {channel.company.location.city || channel.company.location.state || channel.company.location.country}
                        </li>
                        <li>
                            <i class="icon icon--sm icon-Suitcase"></i> {channel.company.live_jobs} Jobs
                        </li>
                      </ul>
                      {
                        channel.company.branding_channel &&
                        <a href={companyLink}>Back to Company Profile</a>
                      }

                    </div>
                  </div>

                </div>
              </div>
              {/* End : Top Company details */}

              <Banner
                searchTerm={searchTerm}
                location={location}
                filters={filters}
                page={companyJobsPage}
                size={"height-auto"}
              />

              <section className="search-tabs">
                <div className="container">
                  <h2 className="company-jobs-subheading">Current Jobs in {channel.name}</h2>
                  <JobResults
                    companySlug={companySlug}
                    searchTerm={searchTerm}
                    location={location}
                    filters={filters}
                    partner={partner}
                    pageCount={pageCount}
                    page={"company-job"}
                  />
                </div>
              </section>

            
            <Footer
              partner={partner}
            />
          </div>

        </div>

      </>
    )
  }
}