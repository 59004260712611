import React from "react";

export default class PartnerBranding extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  render() {
    let {
      partner,
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          .channel-choices .input-checkbox.input-checkbox--switch input:checked+label::before,
          .btn--primary, .btn--primary:visited, .btn--primary:hover, .rightcol .jobs .logo h2,
          .bg--primary, .btn--primary.v1:hover, .btn.v2:hover, .icon-Bell,
          .pages.ui-jobs .search-tabs .search-jobs .item::before,
          .react-js-pagination .page-item.active .page-link {
            background-color: ${partner.colour};
          }
          .channel-choices .input-checkbox.input-checkbox--switch input:checked+label::before,
          .channel-choices .input-checkbox.input-checkbox--switch label:hover::before,
          .channel-choices .input-checkbox input:checked+label,
          .channel-choices .input-checkbox input+label:hover,
          .btn--primary,
          .btn--primary:visited,
          .btn--primary:hover,
          .rightcol .jobs .logo h2,
          .job-cards .row:hover,
          .job-cards .row.active,
          input[type]:focus,
          select:focus,
          textarea:focus,
          .input-select select:focus,
          .react-select-async-paginate div[class$="control"]:hover,
          .react-js-pagination .page-item.active .page-link{
            border-color: ${partner.colour};
          }
          a, a:focus, .btn--primary.v1 .btn__text,
          .react-js-pagination .page-item:not(.active) .page-link{
            color: ${partner.colour};
          }
          .input-checkbox input:checked + label{
            border-color: ${partner.settings.secondary_color};
            background: ${partner.settings.secondary_color};
          }
          .input-radio input:checked + label{
            border-color: ${partner.settings.secondary_color};
            background: ${partner.settings.secondary_color};
          }
          .company_link {
            color: ${partner.settings.secondary_color} !important;
            font-weight: 400;
          }
          a:hover{
            color: ${partner.settings.secondary_color};
          }
          .color--primary{
            color: ${partner.settings.secondary_color};
          }
          .input-checkbox label:hover{
            border-color: ${partner.settings.secondary_color};
          }
          .input-radio label:hover{
            border-color: ${partner.settings.secondary_color};
          }
          .input-checkbox input:checked + label:before {
            animation: none;
            -webkit-animation: none;
          }
          .input-radio input:checked + label:before {
            animation: none;
            -webkit-animation: none;
          }
          .checkbox-wrapper {
            padding-top: 10px;
            padding-bottom: 3px;
            max-height: 200px;
            overflow: auto;
          }
          .radio-wrapper {
            padding-top: 10px;
            padding-bottom: 3px;
            max-height: 200px;
            overflow: auto;
          }
          .input-checkbox label {
            float: left;
          }
          .input-radio label {
            float: left;
          }
          .search-filter .input-checkbox label {
            float: left;
            background: #ffffff;
          }
          @media (min-width: 768px)
          .checkbox-wrapper span {
            width: 122px;
            display: block;
            margin-left: 30px;
          }
          .modal-instance:not(.block) {
            display: block;
          }
          .featured-company {
            color: ${partner.colour} !important;
          }
          .featured-company:hover {
            color: ${partner.settings.secondary_color} !important;
          }
        `}}
      />
    )

  }

}